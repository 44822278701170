/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import ReactLoading from "react-loading";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDButton from "components/MDButton";
import theme from "assets/theme";
import React from "react";
import { catchError } from "service/api";
import { apiGet } from "service/api";
import MDBadge from "components/MDBadge";
import { apiDelete } from "service/api";
import { toast } from "react-toastify";
import {
  Divider,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { DarkMode } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { format, parseISO, subYears, addDays } from "date-fns";
import MDInput from "components/MDInput";
import { setMaskRunTimeDinheiro } from "layouts/helpers/mascaraDinheiro";
import { apiPut } from "service/api";
import { removeMaskDinheiro } from "layouts/helpers/mascaraDinheiro";

export function Presenca() {
  const navigate = useNavigate();

  const [load, setLoad] = React.useState(true);
  const [loadSalvar, setLoadSalvar] = React.useState(null);
  const [data, setData] = React.useState([]);

  const [dataFilter, setDataFilter] = React.useState(
    format(new Date(), "yyyy-MM-dd")
  );

  async function getData(data = null) {
    try {
      setLoad(true);
      const response = await apiGet(
        `/presence?date=${data === null ? dataFilter : data}`
      );
      setData(response);
    } catch (e) {
      catchError(e);
    } finally {
      setLoad(false);
    }
  }

  React.useEffect(() => {
    getData();
  }, []);

  async function salvar(proximo = false) {
    const auxData = [];

    data.forEach((colaborador) => {
      const presenca = document.getElementById(
        `col-${colaborador.id}-presenca`
      );
      const folga = document.getElementById(`col-${colaborador.id}-folga`);
      const falta = document.getElementById(`col-${colaborador.id}-falta`);

      let lack;
      let dayOff;
      let dayOffExchange;

      if (falta.checked === true) {
        lack = true;
        dayOff = false;
        dayOffExchange = false;
      } else {
        lack = false;

        // Folga.
        if (folga.checked === true) {
          if (
            colaborador.e_folga_automatica == true &&
            colaborador.e_folga_manual == false
          ) {
            dayOff = false;
            dayOffExchange = false;
          } else {
            dayOff = true;
            dayOffExchange = false;
          }
        } else {
          // Compareceu.
          if (presenca.checked === true) {
            if (colaborador.compareceu == true) {
              dayOff = false;
              dayOffExchange = true;
            } else {
              if (
                colaborador.e_folga_automatica == true ||
                colaborador.e_folga_manual == true
              ) {
                dayOff = false;
                dayOffExchange = true;
              } else {
                if (colaborador.faltou === true) {
                  dayOff = false;
                  dayOffExchange = true;
                } else {
                  dayOff = false;
                  dayOffExchange = false;
                }
              }
            }
          } else {
            dayOff = false;
            dayOffExchange = false;
          }
        }
      }

      const temDado = lack || dayOff || dayOffExchange;

      if (temDado) {
        auxData.push({
          id: colaborador.id,
          day_off_exchange: dayOffExchange,
          day_off: dayOff,
          lack: lack,
        });
      }
    });

    try {
      setLoadSalvar(true);
      await apiPut(`/presence?date=${dataFilter}`, {
        data: auxData,
      });

      toast.success("Registro salvo.");

      if (proximo) {
        const proximaData = addDays(parseISO(`${dataFilter}`), 1);
        setDataFilter(format(proximaData, "yyyy-MM-dd"));
        getData(format(proximaData, "yyyy-MM-dd"));
      }
    } catch (e) {
      catchError(e);
    } finally {
      setLoadSalvar(false);
    }
  }

  function onChange(value, id, tipo) {
    const presenca = document.getElementById(`col-${id}-presenca`);
    const folga = document.getElementById(`col-${id}-folga`);
    const falta = document.getElementById(`col-${id}-falta`);

    if (tipo == "PR") {
      presenca.checked = true;
      presenca.setAttribute("checked", "checked");

      folga.checked = false;
      folga.removeAttribute("checked");

      falta.checked = false;
      falta.removeAttribute("checked");
    }

    if (tipo == "FO") {
      folga.checked = true;
      folga.setAttribute("checked", "checked");

      presenca.checked = false;
      presenca.removeAttribute("checked");

      falta.checked = false;
      falta.removeAttribute("checked");
    }

    if (tipo == "FA") {
      falta.checked = true;
      falta.setAttribute("checked", "checked");

      folga.checked = false;
      folga.removeAttribute("checked");

      presenca.checked = false;
      presenca.removeAttribute("checked");
    }
  }

  React.useEffect(() => {
    if (data.length > 0 && load == false) {
      data.forEach((colaborador) => {
        const presenca = document.getElementById(
          `col-${colaborador.id}-presenca`
        );
        const folga = document.getElementById(`col-${colaborador.id}-folga`);
        const falta = document.getElementById(`col-${colaborador.id}-falta`);

        if (colaborador.compareceu == true) {
          presenca.checked = true;
          presenca.setAttribute("checked", "checked");

          folga.checked = false;
          folga.removeAttribute("checked");

          falta.checked = false;
          falta.removeAttribute("checked");
        } else if (colaborador.faltou == true) {
          falta.checked = true;
          falta.setAttribute("checked", "checked");

          folga.checked = false;
          folga.removeAttribute("checked");

          presenca.checked = false;
          presenca.removeAttribute("checked");
        } else if (
          colaborador.e_folga_automatica == true ||
          colaborador.e_folga_manual == true
        ) {
          folga.checked = true;
          folga.setAttribute("checked", "checked");

          falta.checked = false;
          falta.removeAttribute("checked");

          presenca.checked = false;
          presenca.removeAttribute("checked");
        } else {
          presenca.checked = true;
          presenca.setAttribute("checked", "checked");

          folga.checked = false;
          folga.removeAttribute("checked");

          falta.checked = false;
          falta.removeAttribute("checked");
        }
      });
    }
  }, [data, load]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Presença
                </MDTypography>

                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{ gap: 16 }}
                >
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={() => salvar()}
                  >
                    {loadSalvar ? "Salvando" : "Salvar"}
                  </MDButton>

                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => salvar(true)}
                  >
                    {loadSalvar ? "Salvando" : "Salvar/Próximo"}
                  </MDButton>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <div
                  style={{
                    width: "100%",
                    paddingLeft: 18,
                    paddingRight: 18,
                    paddingBottom: 20,
                    gap: 16,
                    display: "flex",
                  }}
                >
                  <MDBox
                    mb={4}
                    width={"100%"}
                    display="flex"
                    style={{ gap: 16, maxWidth: 400 }}
                  >
                    <MDInput
                      type="date"
                      label="Data"
                      fullWidth
                      value={dataFilter}
                      onChange={(e) => setDataFilter(e.target.value)}
                    />
                    <MDButton
                      variant="gradient"
                      color="success"
                      onClick={() => getData()}
                    >
                      Aplicar
                    </MDButton>
                  </MDBox>
                </div>

                {load ? (
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: 40,
                      marginBottom: 60,
                    }}
                  >
                    <ReactLoading
                      type={"spin"}
                      color={theme.palette.secondary}
                      height={"32px"}
                      width={"32px"}
                    />
                  </div>
                ) : (
                  <>
                    <MDTypography
                      mt={-3}
                      mx={2}
                      variant="body1"
                      fontWeight="regular"
                      color="dark"
                    >
                      Colaboradores:
                    </MDTypography>

                    <MDBox mx={2} mt={2}>
                      {data.map((item, index) => (
                        <>
                          <MDBox
                            key={item.id}
                            display="flex"
                            alignItems="center"
                            style={{ marginBottom: 16 }}
                          >
                            <MDBox
                              display="flex"
                              alignItems="center"
                              flexDirection="column"
                              justifyContent="center"
                              style={{ marginRight: 20 }}
                            >
                              <input
                                type="checkbox"
                                id={`col-${item.id}-presenca`}
                                style={{ height: 30, width: 30 }}
                                onChange={(e) =>
                                  onChange(e.target.checked, item.id, "PR")
                                }
                              />
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="dark"
                              >
                                PRESENÇA
                              </MDTypography>
                            </MDBox>
                            <MDBox
                              display="flex"
                              alignItems="center"
                              flexDirection="column"
                              justifyContent="center"
                              style={{ marginRight: 20 }}
                            >
                              <input
                                type="checkbox"
                                id={`col-${item.id}-folga`}
                                style={{
                                  height: 30,
                                  width: 30,
                                  accentColor: "#00bbd4",
                                }}
                                onChange={(e) =>
                                  onChange(e.target.checked, item.id, "FO")
                                }
                              />
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="dark"
                              >
                                FOLGA
                              </MDTypography>
                            </MDBox>
                            <MDBox
                              display="flex"
                              alignItems="center"
                              flexDirection="column"
                              justifyContent="center"
                              style={{ marginRight: 20 }}
                            >
                              <input
                                type="checkbox"
                                id={`col-${item.id}-falta`}
                                style={{
                                  height: 30,
                                  width: 30,
                                  accentColor: "red",
                                }}
                                onChange={(e) =>
                                  onChange(e.target.checked, item.id, "FA")
                                }
                              />
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="dark"
                              >
                                FALTA
                              </MDTypography>
                            </MDBox>
                            <MDBox
                              ml={1}
                              mr={3}
                              style={{ width: 90 }}
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <MDBox
                                mb={1}
                                style={{ width: 90 }}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <MDBadge
                                  badgeContent={
                                    item.e_folga_manual
                                      ? "Folga manual"
                                      : item.e_folga_automatica
                                      ? "Folga automática"
                                      : item.faltou
                                      ? "Falta"
                                      : item.compareceu
                                      ? "Troca de folga"
                                      : "Sem observação"
                                  }
                                  color={
                                    item.e_folga_manual
                                      ? "info"
                                      : item.e_folga_automatica
                                      ? "info"
                                      : item.faltou
                                      ? "error"
                                      : item.compareceu
                                      ? "success"
                                      : "light"
                                  }
                                  variant="gradient"
                                  size="sm"
                                />
                              </MDBox>
                              <MDBox
                                style={{ width: 90 }}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <MDBadge
                                  badgeContent={
                                    item.tem_lancamento
                                      ? "Tem lançamentos"
                                      : "Sem lançamentos"
                                  }
                                  color={
                                    item.tem_lancamento ? "warning" : "light"
                                  }
                                  variant="gradient"
                                  size="sm"
                                />
                              </MDBox>
                            </MDBox>
                            <MDBox>
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="dark"
                              >
                                {item.nome}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <Divider />
                        </>
                      ))}
                    </MDBox>
                  </>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
