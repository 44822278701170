export function setMaskDinheiro(value) {
  /* 150060000 => 1.500.600,00 */

  if (value) {
    value = value.toLocaleString("pt-br", { minimumFractionDigits: 2 });
  }

  return value;
}

export function setMaskRunTimeDinheiro(
  value = "",
  event = undefined,
  onChange = undefined
) {
  /* 150060000 => 1.500.600,00 */

  let newValue = value.replace(/\D/g, "");

  newValue = (parseFloat(newValue) / 100).toFixed(2);
  newValue = newValue.replace(".", ",");
  newValue = newValue.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
  newValue = newValue.replace(/(\d)(\d{3}),/g, "$1.$2,");

  value = newValue;

  //----------------------

  if (event != undefined) {
    event.target.value = value;
  }

  if (onChange != undefined) {
    onChange(value);
  }

  return value;
}

export function setMaskPositiveOrNegativeDinheiro(
  value = "",
  event = undefined,
  onChange = undefined
) {
  let prefix = "";

  if (value.charAt(0) === "-") {
    prefix = "-";
    value = value.substring(1);
  }

  let newValue = value.replace(/\D/g, "");

  newValue = (parseFloat(newValue) / 100).toFixed(2);
  newValue = newValue.replace(".", ",");
  newValue = newValue.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
  newValue = newValue.replace(/(\d)(\d{3}),/g, "$1.$2,");

  value = newValue;

  //----------------------

  if (event !== undefined) {
    event.target.value = value;
  }

  if (onChange !== undefined) {
    onChange(value);
  }

  return `${prefix}${value}`;
}

export function removeMaskDinheiro(value) {
  /* 1.500.600,00 => 1500600.00 */

  if (value) {
    value = value.replace(/\./gi, "");
    value = value.replace(/\,/gi, ".");
  }

  return value;
}
