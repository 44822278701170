/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import ReactLoading from "react-loading";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import MDButton from "components/MDButton";
import theme from "assets/theme";
import React from "react";
import { catchError } from "service/api";
import { apiGet } from "service/api";
import MDBadge from "components/MDBadge";
import { apiDelete } from "service/api";
import { toast } from "react-toastify";
import {
  Divider,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { DarkMode } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { format, parseISO, subYears, addDays } from "date-fns";
import MDInput from "components/MDInput";
import { setMaskRunTimeDinheiro } from "layouts/helpers/mascaraDinheiro";
import { apiPut } from "service/api";
import { removeMaskDinheiro } from "layouts/helpers/mascaraDinheiro";

export function Lancamento() {
  const navigate = useNavigate();

  const [load, setLoad] = React.useState(true);
  const [loadSalvar, setLoadSalvar] = React.useState(null);
  const [data, setData] = React.useState([]);

  const [dataFilter, setDataFilter] = React.useState(
    format(new Date(), "yyyy-MM-dd")
  );

  async function getData(data = null) {
    try {
      setLoad(true);
      const response = await apiGet(
        `/launch?date=${data === null ? dataFilter : data}`
      );
      setData(response);
    } catch (e) {
      catchError(e);
    } finally {
      setLoad(false);
    }
  }

  React.useEffect(() => {
    getData();
  }, []);

  async function salvar(proximo = false) {
    const auxData = [];

    data.forEach((item) => {
      const valorTotal = document.getElementById(
        `colaborador-${item.id}-valor`
      );

      const valorComissao = document.getElementById(
        `colaborador-${item.id}-valor-comissao`
      );

      let valorFinalTotal = 0;
      let valorFinalComissao = 0;

      if (!!valorTotal.value !== false) {
        let valorTotalFormatado = removeMaskDinheiro(valorTotal.value);
        if (valorTotalFormatado >= 0) {
          valorFinalTotal = valorTotalFormatado;
        }
      }

      if (!!valorComissao.value !== false) {
        let valorComissaoFormatado = removeMaskDinheiro(valorComissao.value);
        if (valorComissaoFormatado >= 0) {
          valorFinalComissao = valorComissaoFormatado;
        }
      }

      auxData.push({
        id: item.id,
        amount: valorFinalTotal,
        commission_amount: valorFinalComissao,
      });
    });

    try {
      setLoadSalvar(true);
      await apiPut(`/launch?date=${dataFilter}`, {
        data: auxData,
      });

      toast.success("Registro salvo.");

      if (proximo) {
        const proximaData = addDays(parseISO(`${dataFilter}`), 1);
        setDataFilter(format(proximaData, "yyyy-MM-dd"));
        getData(format(proximaData, "yyyy-MM-dd"));
      }
    } catch (e) {
      catchError(e);
    } finally {
      setLoadSalvar(false);
    }
  }

  function onKey(keyCode, idItem, index, primeiroInput) {
    if (keyCode === 13) {
      // Verificando se é o último input

      if (primeiroInput === false) {
        const qtdColaborador = data.length;
        if (qtdColaborador - 1 == index) {
          salvar(true);
        } else {
          const buscarProximo = data[index + 1];
          const inputValorTotal = document.getElementById(
            `colaborador-${buscarProximo.id}-valor`
          );
          if (inputValorTotal) {
            inputValorTotal.focus();
          }
        }
      } else {
        const inputComissao = document.getElementById(
          `colaborador-${idItem}-valor-comissao`
        );
        if (inputComissao) {
          inputComissao.focus();
        }
      }
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Lançamentos
                </MDTypography>

                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{ gap: 16 }}
                >
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={() => salvar()}
                  >
                    {loadSalvar ? "Salvando" : "Salvar"}
                  </MDButton>

                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => salvar(true)}
                  >
                    {loadSalvar ? "Salvando" : "Salvar/Próximo"}
                  </MDButton>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <div
                  style={{
                    width: "100%",
                    paddingLeft: 18,
                    paddingRight: 18,
                    paddingBottom: 20,
                    gap: 16,
                    display: "flex",
                  }}
                >
                  <MDBox
                    mb={4}
                    width={"100%"}
                    display="flex"
                    style={{ gap: 16, maxWidth: 400 }}
                  >
                    <MDInput
                      type="date"
                      label="Data"
                      fullWidth
                      value={dataFilter}
                      onChange={(e) => setDataFilter(e.target.value)}
                    />
                    <MDButton
                      variant="gradient"
                      color="success"
                      onClick={() => getData()}
                    >
                      Aplicar
                    </MDButton>
                  </MDBox>
                </div>

                {load ? (
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: 40,
                      marginBottom: 60,
                    }}
                  >
                    <ReactLoading
                      type={"spin"}
                      color={theme.palette.secondary}
                      height={"32px"}
                      width={"32px"}
                    />
                  </div>
                ) : (
                  <>
                    <MDTypography
                      mt={-3}
                      mx={2}
                      variant="body1"
                      fontWeight="regular"
                      color="dark"
                    >
                      Vendedores:
                    </MDTypography>

                    <MDBox mx={2} mt={2}>
                      {data.map((item, index) => (
                        <>
                          <MDBox
                            key={item.id}
                            display="flex"
                            alignItems="center"
                            style={{ marginBottom: 16 }}
                          >
                            <MDInput
                              label="Valor total *"
                              style={{ width: 200, marginRight: 20 }}
                              id={`colaborador-${item.id}-valor`}
                              autoFocus={index == 0 ? true : false}
                              onKeyUp={(e) =>
                                onKey(e.keyCode, item.id, index, true)
                              }
                              defaultValue={
                                !!item.amount
                                  ? setMaskRunTimeDinheiro(item.amount)
                                  : "0,00"
                              }
                              onChange={(e) =>
                                setMaskRunTimeDinheiro(e.target.value, e)
                              }
                            />
                            <MDInput
                              label="Valor comissão *"
                              id={`colaborador-${item.id}-valor-comissao`}
                              style={{ width: 200, marginRight: 20 }}
                              onKeyUp={(e) =>
                                onKey(e.keyCode, item.id, index, false)
                              }
                              defaultValue={
                                !!item.commission_amount
                                  ? setMaskRunTimeDinheiro(
                                      item.commission_amount
                                    )
                                  : "0,00"
                              }
                              onChange={(e) =>
                                setMaskRunTimeDinheiro(e.target.value, e)
                              }
                            />
                            <MDBox
                              ml={-1}
                              mr={2}
                              style={{ width: 90 }}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              flexDirection="column"
                            >
                              <MDBadge
                                badgeContent={
                                  item.e_falta
                                    ? "Faltou"
                                    : item.e_folga_manual
                                    ? "Folga Manual"
                                    : item.e_folga
                                    ? "Folga"
                                    : "Presente"
                                }
                                color={
                                  item.e_falta
                                    ? "error"
                                    : item.e_folga_manual
                                    ? "warning"
                                    : item.e_folga
                                    ? "info"
                                    : "success"
                                }
                                variant="gradient"
                                size="sm"
                              />
                              {!!item.excluded_at && (
                                <MDBadge
                                  badgeContent={"Excluído"}
                                  color={"error"}
                                  variant="gradient"
                                  size="sm"
                                  style={{ marginTop: 5 }}
                                />
                              )}
                            </MDBox>
                            <MDBox>
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="dark"
                              >
                                {item.nome}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <Divider />
                        </>
                      ))}
                    </MDBox>
                  </>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
