import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default async function geradorDocumentoPDF(
  content,
  titulo,
  desc,
  setUrl = () => null
) {
  const nomeEstabelecimento = "Pesque Pague Taguatinga";

  let documento = {
    content: content,
  };

  documento.content.unshift(setBorderBottom("#000", [0, 0, 0, 30]));

  documento.content.unshift({
    alignment: "center",
    columns: [
      // { image: imageEmpresaBase64, width: 50 },
      [
        {
          text: nomeEstabelecimento,
          bold: true,
          fontSize: 18,
          margin: [0, 0, 0, 10],
        },
        {
          text: titulo,
          margin: [0, 0, 0, 4],
        },
        {
          text: desc,
          italics: true,
          fontSize: 11,
          color: "#999",
        },
      ],
    ],
  });

  documento.footer = function (currentPage, pageCount, pageSize) {
    return [
      {
        text: "Página " + currentPage.toString() + " de " + pageCount,
        alignment: "right",
        italics: true,
        margin: [0, 0, 40, 0],
      },
    ];
  };

  pdfMake.createPdf(documento).getDataUrl((dataUrl) => {
    setUrl(dataUrl);
  });
}

/* ESTILIZAÇÃO */
export function setLine(
  valueA,
  valueB,
  colorValueB = "#000",
  alignment = "justify"
) {
  return {
    margin: [0, 0, 0, 5],
    alignment: alignment,
    columns: [
      {
        alignment: "left",
        text: valueA,
      },
      {
        alignment: "right",
        text: valueB,
        bold: true,
        color: colorValueB,
      },
    ],
  };
}

export function setBorderBottom(color = "#000", margin = [0]) {
  return {
    text: "_______________________________________________________________________________________________",
    margin: margin,
    color: color,
  };
}
