/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import { AuthContext } from "context/AuthContext";
import MDTypography from "components/MDTypography";
import { catchError } from "service/api";
import axios from "axios";
import { apiGet } from "service/api";
import constants from "constants";
import { apiPost } from "service/api";

function DashboardNavbar({ absolute, light, isMini }) {
  const { sair, usuario } = React.useContext(AuthContext);

  const navigate = useNavigate();

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    />
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const [bloqueado, setBloqueado] = useState(false);
  const [messageExpiresIn, setMessageExpiresIn] = useState("");

  const [finishConfig, setFinishConfig] = useState(false);

  async function setConfig(valor) {
    try {
      await apiPost(`/suporteconfig`, {
        valor: valor,
      });
    } catch (e) {
      catchError(e);
    }
  }

  async function getConfig() {
    try {
      setFinishConfig(false);

      const response = await apiGet(`config`);
      setBloqueado(response.bloqueado);
    } catch (e) {
      catchError(e);
    } finally {
      setFinishConfig(true);
    }
  }

  async function getExpiresIn() {
    try {
      setFinishConfig(false);

      const response = await apiGet(`expires_in`);

      if (response) {
        if (response.date && response.message) {
          setMessageExpiresIn(response.message);
        }
      }
    } catch (e) {
      catchError(e);
    } finally {
      setFinishConfig(true);
    }
  }

  useEffect(() => {
    if (usuario?.e_sistema) {
      getConfig();
    }
  }, [usuario]);

  useEffect(() => {
    getExpiresIn();
  }, [])

  return (
    <>
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) =>
          navbar(theme, { transparentNavbar, absolute, light, darkMode })
        }
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            <Breadcrumbs
              icon="home"
              title={route[route.length - 1]}
              route={route}
              light={light}
            />
          </MDBox>
          {isMini ? null : (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              <MDBox color={light ? "white" : "inherit"}>
                {usuario?.e_sistema && finishConfig ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 14,
                        color: "#999",
                        fontWeight: "bold",
                        marginRight: 4,
                      }}
                    >
                      Sistema bloqueado
                    </span>
                    <input
                      defaultChecked={bloqueado}
                      onChange={(e) => {
                        setConfig(e.target.checked);
                      }}
                      style={{ height: 20, width: 20 }}
                      type="checkbox"
                    />
                  </div>
                ) : null}

                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  sx={{ lineHeight: 0 }}
                  color="text"
                >
                  Olá, {!!usuario?.nome ? usuario?.nome.split(" ")[0] : "usuário"}
                  .
                </MDTypography>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
                <IconButton
                  onClick={() => {
                    sair();
                    navigate("/login", { replace: true });
                  }}
                  sx={navbarIconButton}
                  size="small"
                  disableRipple
                >
                  <Icon sx={iconsStyle}>logout</Icon>
                </IconButton>
                {renderMenu()}
              </MDBox>
            </MDBox>
          )}
        </Toolbar>
      </AppBar>

      {messageExpiresIn && (
        <div style={{ marginBottom: "1rem", padding: "1rem", borderRadius: "1rem", backgroundColor: "#e74c3c", border: "1px solid #c0392b" }}>
          <h4 style={{ color: "#FFF" }}>{messageExpiresIn}</h4>
        </div>
      )}

    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
