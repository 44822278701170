/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

import ReactLoading from "react-loading";

// Data
import MDButton from "components/MDButton";
import theme from "assets/theme";
import React from "react";
import { catchError } from "service/api";
import { apiGet } from "service/api";
import MDBadge from "components/MDBadge";
import { apiDelete } from "service/api";
import { toast } from "react-toastify";
import { Divider, Icon, MenuItem, Select, Switch } from "@mui/material";
import { DarkMode } from "@mui/icons-material";
import MDInput from "components/MDInput";
import { useLocation, useNavigate } from "react-router-dom";
import { apiPost } from "service/api";
import { apiPut } from "service/api";

import { format, parseISO, isValid, startOfMonth, endOfMonth } from "date-fns";
import pt from "date-fns/locale/pt";
import {} from "layouts/helpers/mascaraDinheiro";
import { setMaskRunTimeDinheiro } from "layouts/helpers/mascaraDinheiro";
import { removeMaskDinheiro } from "layouts/helpers/mascaraDinheiro";

export function MetaCadastro() {
  const navigate = useNavigate();
  const location = useLocation();

  const [loadContainer, setLoadContainer] = React.useState(
    location.state == null ? false : true
  );
  const [colaboradores, setColaboradores] = React.useState([]);
  const [colaboradoresMeta, setColaboradoresMeta] = React.useState([]);

  async function getColaboradores() {
    try {
      setLoadContainer(true);

      const response = await apiGet("collaborator");
      const responseMeta = await apiGet(
        `objective/${location.state?.id}/collaborator`
      );

      const aux = [];

      response.forEach((item) => {
        if (item.e_garcon) {
          aux.push(item);
        }
      });

      setColaboradoresMeta(responseMeta["collaborators-in-objective"]);
      setColaboradores(aux);
    } catch (e) {
      catchError(e);
      navigate("/meta");
    } finally {
      setLoadContainer(false);
    }
  }

  React.useEffect(() => {
    if (colaboradores.length > 0 && loadContainer == false) {
      colaboradoresMeta.forEach((item) => {
        const valorMinimo = document.getElementById(
          `colaborador-${item.id_colaborador}-valor-minimo`
        );

        const valorAlvo = document.getElementById(
          `colaborador-${item.id_colaborador}-valor-alvo`
        );

        const switchDoc = document.getElementById(
          `colaborador-${item.id_colaborador}`
        );

        if (valorMinimo) {
          valorMinimo.value = setMaskRunTimeDinheiro(
            parseFloat(item.valor_minimo).toFixed(2)
          );
        }

        if (valorAlvo) {
          valorAlvo.value = setMaskRunTimeDinheiro(
            parseFloat(item.valor_desejado).toFixed(2)
          );
        }

        if (switchDoc) {
          switchDoc.checked = true;
          switchDoc.setAttribute("checked", "checked");
        }
      });
    }
  }, [colaboradores, loadContainer]);

  React.useEffect(() => {
    if (location.state != null) {
      getColaboradores();
    }
  }, []);

  const [load, setLoad] = React.useState(false);

  const [nome, setNome] = React.useState("");
  const [inicio, setInicio] = React.useState("");
  const [final, setFinal] = React.useState("");

  React.useEffect(() => {
    if (location.state != null) {
      const item = location.state;

      setNome(item.nome);
      setInicio(item.dt_inicio);
      setFinal(item.dt_final);
    } else {
      const dataAtual = new Date();
      setNome(
        `${format(dataAtual, "MMMM", { locale: pt })} - ${format(
          dataAtual,
          "yyyy"
        )}`.toUpperCase()
      );
      setInicio(format(startOfMonth(dataAtual), "yyyy-MM-dd"));
      setFinal(format(endOfMonth(dataAtual), "yyyy-MM-dd"));
    }
  }, []);

  async function salvar() {
    if (!nome) {
      toast.error("Insira um nome.");
      return;
    }

    if (!inicio) {
      toast.error("Insira uma data inicial.");
      return;
    }

    if (!final) {
      toast.error("Insira uma data final.");
      return;
    }

    if (!isValid(new Date(inicio))) {
      toast.error("Insira uma data inicial válida.");
      return;
    }

    if (!isValid(new Date(final))) {
      toast.error("Insira uma data final válida.");
      return;
    }

    try {
      setLoad(true);

      const data = {
        nome: nome,
        dt_inicio: inicio,
        dt_final: final,
      };

      if (location.state == null) {
        await apiPost("/objective", data);
        toast.success("Registro salvo.");
      } else {
        const auxData = [];
        let temErro = false;

        colaboradores.forEach((item) => {
          const valorMinimo = document.getElementById(
            `colaborador-${item.id}-valor-minimo`
          );

          const valorAlvo = document.getElementById(
            `colaborador-${item.id}-valor-alvo`
          );

          const switchDoc = document.getElementById(`colaborador-${item.id}`);

          if (switchDoc) {
            if (switchDoc.checked) {
              if (!!valorMinimo.value === false) {
                toast.error("Infome um valor mínimo maior que 0.");
                temErro = true;
                return;
              }

              let valorMinimoFormatado = removeMaskDinheiro(valorMinimo.value);
              if (valorMinimoFormatado <= 0) {
                toast.error("Infome um valor mínimo maior que 0.");
                temErro = true;
                return;
              }

              if (!!valorAlvo.value === false) {
                toast.error("Infome um valor alvo maior que 0.");
                temErro = true;
                return;
              }

              let valorAlvoFormatado = removeMaskDinheiro(valorAlvo.value);
              if (valorAlvoFormatado <= 0) {
                toast.error("Infome um valor alvo maior que 0.");
                temErro = true;
                return;
              }

              auxData.push({
                id: item.id,
                value_min: valorMinimoFormatado,
                value_objective: valorAlvoFormatado,
              });
            }
          }
        });

        if (temErro) return;

        await apiPut(`objective/${location.state?.id}`, data);
        await apiPut(`objective/${location.state?.id}/collaborator`, {
          collaborators: auxData,
        });

        toast.success("Registro editado.");
      }

      navigate("/meta");
    } catch (e) {
      catchError(e);
    } finally {
      setLoad(false);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDButton
        variant="gradient"
        color="light"
        onClick={() => navigate("/meta")}
      >
        Voltar
      </MDButton>
      <MDBox pt={6} pb={3}>
        {loadContainer ? (
          <div
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              marginTop: 40,
              marginBottom: 60,
            }}
          >
            <ReactLoading
              type={"spin"}
              color={theme.palette.secondary}
              height={"32px"}
              width={"32px"}
            />
          </div>
        ) : (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <MDTypography variant="h6" color="white">
                    {location.state == null ? "Cadastrar" : "Editar"} Meta
                  </MDTypography>

                  <MDButton variant="gradient" color="dark" onClick={salvar}>
                    {load ? "Salvando" : "Salvar"}
                  </MDButton>
                </MDBox>

                <MDBox p={3}>
                  <MDBox
                    mb={2}
                    width={"100%"}
                    display="flex"
                    style={{ gap: 16 }}
                  >
                    <MDInput
                      label="Nome *"
                      fullWidth
                      value={nome}
                      onChange={(e) => setNome(e.target.value)}
                    />
                  </MDBox>

                  <MDBox
                    mb={4}
                    width={"100%"}
                    display="flex"
                    style={{ gap: 16 }}
                  >
                    <MDInput
                      label="Período inicial *"
                      type="date"
                      fullWidth
                      value={inicio}
                      onChange={(e) => setInicio(e.target.value)}
                      placeholder="Período inicial"
                    />
                    <MDInput
                      label="Período final *"
                      type="date"
                      fullWidth
                      value={final}
                      onChange={(e) => setFinal(e.target.value)}
                      placeholder="Período final"
                    />
                  </MDBox>

                  {location.state != null && (
                    <>
                      <Divider />

                      <MDTypography
                        variant="body2"
                        fontWeight="regular"
                        color="dark"
                        style={{ marginBottom: 20 }}
                      >
                        Colaboradores participantes da meta:
                      </MDTypography>

                      {colaboradores.length === 0 && (
                        <MDTypography
                          variant="span"
                          fontWeight="regular"
                          color="dark"
                        >
                          Nenhum colaborador que pode vender foi encontrado.
                        </MDTypography>
                      )}

                      {colaboradores.map((item) => (
                        <>
                          <MDBox
                            key={item.id}
                            display="flex"
                            alignItems="center"
                            style={{ marginBottom: 16 }}
                          >
                            <MDInput
                              label="Valor mínimo *"
                              style={{ width: 200, marginRight: 20 }}
                              id={`colaborador-${item.id}-valor-minimo`}
                              onChange={(e) =>
                                setMaskRunTimeDinheiro(e.target.value, e)
                              }
                            />
                            <MDInput
                              label="Valor alvo *"
                              id={`colaborador-${item.id}-valor-alvo`}
                              style={{ width: 200, marginRight: 20 }}
                              onChange={(e) =>
                                setMaskRunTimeDinheiro(e.target.value, e)
                              }
                            />
                            <MDBox mt={0.5} style={{ marginRight: 20 }}>
                              <input
                                type="checkbox"
                                id={`colaborador-${item.id}`}
                                style={{ height: 30, width: 30 }}
                              />
                            </MDBox>
                            <MDBox>
                              <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="dark"
                              >
                                {item.nome}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <Divider />
                        </>
                      ))}
                    </>
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        )}
      </MDBox>
    </DashboardLayout>
  );
}
