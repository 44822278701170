import axios from "axios";
import constants from "constants";

import { toast } from "react-toastify";

const api = axios.create({
  baseURL: constants.baseURL,
});

api.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error.response);
  }
);

function getToken() {
  let tk = sessionStorage.getItem("metacontrol@1.0.0-token");

  if (tk != undefined && tk != "" && tk != null) {
    api.defaults.headers.common["authorization"] = tk;
  }
}

export function apiGet(url) {
  getToken();
  return api.get(url);
}

export function apiPost(url, data) {
  getToken();
  return api.post(url, data);
}

export function apiPut(url, data) {
  getToken();
  return api.put(url, data);
}

export function apiDelete(url) {
  getToken();
  return api.delete(url);
}

export function catchError(
  error,
  mensagemPadrao = "Não foi possível concluir essa operação."
) {
  if (error?.response) {
    toast.error(`${error.response?.data?.error}`);
  } else if (error?.request) {
    if (error?.data?.error) {
      toast.error(error?.data?.error);
    } else {
      toast.error(mensagemPadrao);
    }
  } else {
    if (error?.error) {
      toast.error(error?.error);
    } else {
      toast.error(mensagemPadrao);
    }
  }
}

export default api;
